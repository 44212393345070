.Dropdown {
  color: var(--primary-text-color);
  border: none;
  cursor: pointer;
  text-decoration: none;
  background-color: inherit;
  font: inherit;
  padding: 0;
}

.Dropdown-arrow {
  display: inline;
  margin-left: 0.6rem;
  padding-bottom: 0.1rem;
}

.Dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  z-index: 1;
  right: -0.4rem;
  top: 4rem;
  line-height: 100%;
  text-align: left;
  padding-inline-start: 0;
  list-style: none;
}

.Dropdown-content.shown {
  display: block;
}

.Dropdown-content a {
  padding: 1.6rem;
  width: 17rem;
  height: 5rem;
  font-size: 1.6rem;
  color: var(--black-text-color);
  text-decoration: none;
  display: block;
  box-sizing: border-box;
}

.Dropdown-content a:hover {
  background-color: var(--link-dark-color);
  color: var(--primary-text-color);
}

.Dropdown:focus, .Dropdown a:focus {
  outline: var(--primary-text-color) solid 0.3rem;
  outline-offset: 0.2rem;
}
