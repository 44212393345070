.IDPortenLoginButton {
    display: inline-flex;
    align-items: center;
}

.IDPortenLoginButton > span {
    padding-inline: 0.5rem;
    padding-top: 1.0rem;
    padding-bottom: 0.6rem;
}
