.BaseContainer {
    width: var(--cc-width);
    margin-left: auto;
    margin-right: auto;
    margin-top: 8.4rem;
    font-size: 1.4rem;
}

.BaseContainer-header {
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--secondary-color-4);
    font-size: 1.6rem;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 1.4rem;
}

.BaseContainer-frame {
    background-color: white;
    box-shadow: 0px 0.4rem 1rem rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    padding: var(--cc-padding);
    padding-top: var(--cc-padding-top);
    min-height: var(--cc-frame-height);
    position: relative;
}

@media (max-width: 550px) {
    .BaseContainer {
        width: calc(100% - 2 * var(--cc-margins-small));
        margin-left: var(--cc-margins-small);
        margin-right: var(--cc-margins-small);
    }
}

@media (max-width: 440px) {
    .BaseContainer-frame {
        padding-left: var(--cc-padding-small);
        padding-right: var(--cc-padding-small);
    }
}

@media (max-width: 413px) {
    .BaseContainer-frame {
        padding-left: var(--cc-padding-xs);
        padding-right: var(--cc-padding-xs);
    }
}

@media (max-height: 860px) {
    .BaseContainer {
        margin-top: 5rem;
    }
}

@media (max-height: 760px) {
    .BaseContainer {
        margin-top: 1.6rem;
    }
    .BaseContainer-frame {
        padding-top: var(--cc-padding-top-small);
        padding-bottom: var(--cc-padding-small);
        min-height: var(--cc-frame-height-small);
    }
}

@media (max-height: 639px) {
  .BaseContainer-frame {
      padding-top: var(--cc-padding-top-xs);
      min-height: var(--cc-frame-height-xs);
  }
}