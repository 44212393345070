.ConsentInfoStage-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 12rem;
}

.ConsentInfoStage-section {
    margin-bottom: 2.4rem;
    line-height: 150%;
}

.ConsentInfoStage-ul {
    list-style-type: disc;
    padding-left: 1.4rem;
}

@media screen and (max-width: 320px) {
    .ConsentInfoStage-image {
        width: 80%;
    }
}
