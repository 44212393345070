.LanguageSelector {
    display: flex;
}

.LanguageSelector-current {
    display: flex;
    color: inherit;
    text-decoration: none;
    justify-content: flex-end;
}

.LanguageSelector-image {
    margin-right: 1rem;
    margin-top:-0.8rem;
}

.LanguageSelector:focus {
    outline: var(--primary-text-color) solid 0.3rem;
    outline-offset: 0.2rem;
}

.LanguageSelector-choice {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    margin-right: 3rem;
}

.LanguageSelector-select {
    padding: 0.3rem;
    background-color: var(--primary-color);
    color: var(--primary-text-color);
    border: transparent;
    border-bottom: 1px solid;
    font-style: normal;
    font-size: 1.6rem;
    cursor: pointer;
}

@media (max-width: 1000px) {
    .LanguageSelector-image {
        display: none;
    }
}