.LoginLinkWelcomePage p {
    font-size: 1.4rem;
}

.LoginLinkWelcomePage p.LoginLinkWelcomePage-number {
    margin-top: 1.9rem;
    font-size: 1.6rem;
    font-weight: 600;
}

.LoginLinkWelcomePage-send {
    margin-top: 3.1rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 3.5rem;
}

.LoginLinkWelcomePage-send-text {
    margin: 2.1rem;
}

.LoginLinkWelcomePage-nosms {
    margin-top: 3.5rem;
    text-align: center;
    font-size: 1.6rem;
}
.LoginLinkWelcomePage-nosms a {
    text-decoration: none;
}

.LoginLinkWelcomePage-send > .ButtonSecondary {
    padding: 1rem 0;
    box-sizing: content-box;
}