.LoginPage-intro {
  font-size: 1.6rem;
}

.LoginPage-intro > p {
    margin-top: 6.9rem;
}

.LoginPage-idporten {
    margin: 5rem 0 1.2rem 0;
}

.LoginPage-not-idporten > .ButtonSecondary {
    padding: 0.6rem 0;
    box-sizing: content-box;
}

@media (max-width: 440px) {
    .LoginPage-not-idporten {
        margin-top: 3.2rem;
        margin-bottom: 3.2rem;
    }
}
