.PasswordChangedPage-info {
    margin-bottom: 6rem;
}

.PasswordChangedPage-bottom-container {
    position: relative;
    text-align: center;
    margin-top: 7rem;
}

@media (max-height: 760px) {
    .PasswordChangedPage-bottom-container {
        margin-top: 3.2rem;
    }
}

@media (max-width: 320px) {
    .PasswordChangedPage-info {
        margin-bottom: 2rem;
    }
}
