.errorMessage {
    display: flex;
    align-items: center;
    border: 1px solid #CF3C3A;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.4rem;
    background: rgba(207, 60, 58, 0.08);
}

.errorMessage-icon {
    margin: 1rem;
    width: 20px;
    height: 20px;
}