.ConsentStage-section {
    margin-bottom: 2.4rem;
}

.ConsentStage-section-label {
    margin-left: 2.8rem;
    user-select: none;
    display: block;
}

.ConsentStage-section p {
    margin-left: 2.8rem;
}

@media (max-height: 760px) {
    .ConsentStage-section {
        margin-bottom: 1rem;
    }
}