@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 75%);
  }
  100% {
    background-color: hsl(0, 0%, 95%);
  }
}

.LoadingSkeleton-topbar {
  height: 8rem;
  animation: skeleton-loading 1.7s linear infinite alternate
}

.LoadingSkeleton-startpage-buttons {
  height: calc(100vh - 14.4rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.LoadingSkeleton-startpage-buttons .Button {
  animation: skeleton-loading 1.7s linear infinite alternate
}

.LoadingSkeleton-subpage-container {
  width: var(--cc-width);
  margin-left: auto;
  margin-right: auto;
  margin-top: 8.4rem;
  font-size: 1.4rem;
}
.LoadingSkeleton-subpage-container-frame {
  background-color: white;
  box-shadow: 0px 0.4rem 1rem rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  padding: var(--cc-padding);
  padding-top: var(--cc-padding-top);
  min-height: var(--cc-frame-height);
  position: relative;
}
.LoadingSkeleton-subpage-title {
  width: 65%;
  height: 5rem;
  margin-bottom: 8rem;
  border-radius: 0.4rem;
  animation: skeleton-loading 1.7s linear infinite alternate;
}

.LoadingSkeleton-subpage-text {
  width: 90%;
  height: 1.25rem;
  margin-bottom: 1rem;
  border-radius: 0.4rem;
  animation: skeleton-loading 1.7s linear infinite alternate;
}

.LoadingSkeleton-subpage-button {
  width: 100%;
  height: 5rem;
  margin-top: 12rem;
  border-radius: 0.4rem;
  animation: skeleton-loading 1.7s linear infinite alternate;
}


@media (max-width: 550px) {
  .LoadingSkeleton-subpage-container {
      width: calc(100% - 2 * var(--cc-margins-small));
      margin-left: var(--cc-margins-small);
      margin-right: var(--cc-margins-small);
  }
  .LoadingSkeleton-topbar {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

@media (max-width: 440px) {
  .LoadingSkeleton-subpage-container-frame {
    padding-left: var(--cc-padding-small);
    padding-right: var(--cc-padding-small);
  }
}

@media (max-width: 413px) {
  .LoadingSkeleton-subpage-container-frame {
    padding-left: var(--cc-padding-xs);
    padding-right: var(--cc-padding-xs);
  }
}

@media (max-height: 860px) {
  .LoadingSkeleton-subpage-container {
    margin-top: 5rem;
  }
}

@media (max-height: 760px) {
  .LoadingSkeleton-subpage-container {
    margin-top: 1.6rem;
  }
  .LoadingSkeleton-subpage-container-frame {
    padding-top: var(--cc-padding-top-small);
    padding-bottom: var(--cc-padding-small);
    min-height: var(--cc-frame-height-small);
  }
}

@media (max-height: 639px) {
  .LoadingSkeleton-subpage-container-frame {
    padding-top: var(--cc-padding-top-xs);
    min-height: var(--cc-frame-height-xs);
}
}