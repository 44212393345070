.ActivationStageContainer-content {
    font-size: 1.4rem;
    line-height: 1.7rem;
}

.ActivationStageContainer-button-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content:space-between;    
}

@media (max-width: 510px) {
    .ActivationStageContainer-button-container {
        justify-content: space-around;
        gap: 3rem;
    }
}