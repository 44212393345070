.StartPage {
  text-align: center;
  height: calc(100vh - 15.4rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 575px) and (max-height: 690px) {
  .StartPage {
    height: 100%;
  }  
}
