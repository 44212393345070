.VerificationInputField {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.VerificationInputFieldInput {
    width: 100%;
    height: 7.5rem;
    font-size: 2.4rem;
    text-align: center;
    background: var(--secondary-color-2);
    border: 1px solid var(--secondary-color-3);
    box-sizing: border-box;
    box-shadow: 10px 10px 10px rgba(200, 200, 200, 0.08);
    border-radius: 4px;
    margin: 0.8rem;
}

.VerificationInputFieldInput:focus{
    outline: none;
    box-shadow: 0 0 0 0.3rem var(--focus-color);
}

.VerificationInputField-status-error {
    border-color: #CF3C3A;
    background: rgba(207, 60, 58, 0.08);
}

.VerificationInputField-status-success {
    background: rgba(94,206,84,0.08);
    border: 1px solid var(--bright-green-color);
    box-shadow: 0 0 0 0.2rem var(--bright-green-color);
}
.VerificationInputField-status-success:focus {
    box-shadow: 0 0 0 0.2rem var(--bright-green-color);
}

.VerificationInputFieldInput:disabled {
    background-color: var(--secondary-color-3);
    color: var(--black-text-color);
    cursor: not-allowed;
}

.VerificationInputField-status-success:disabled {
    background: rgba(94,206,84,0.08);
}

@media (max-width: 350px) {
    .VerificationInputFieldInput {
        height: 5.5rem;
        margin: 0.5rem;
        width: 4rem;
    }
}

@media (min-width: 350px) and (max-width: 410px) {
    .VerificationInputFieldInput {
        height: 6.5rem;
        margin: 0.5rem;
        width: 2em;
    }
}

@media (min-width: 410px) and (max-width: 440px) {
    .VerificationInputFieldInput {
        height: 6.5rem;
        margin: 0.5rem;
        width: 5.5rem;
    }
}