@keyframes ChangeImagePage-loading {
    to {
        background-position: 150px 0, 0 100px;
    }
}

.ChangeImagePage-image-section {
    display: grid;
    grid-auto-flow: column;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
    color: var(--link-color);
}

.ChangeImagePage-image-div {
    margin: auto;
    height: 100%;
}

.ChangeImagePage-image {
    cursor: pointer;
    clip-path: circle();
    height: 14rem;
}


.ChangeImagePage-image-loading-background {
    height: 14rem;
    width: 14rem;
    justify-content: center;
    background-color: var(--secondary-color-3);
    border-radius: 50%;
}

.ChangeImagePage-image-loading {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: 
        linear-gradient(0.25turn, transparent, #E9E9E9, transparent);
    background-repeat: no-repeat;
    background-size: 50% 100%, 100% 100%;
    background-position: -85px 0, 0 0px;
    animation: ChangeImagePage-loading 2.2s infinite
}

.ChangeImagePage-image-section .ButtonSecondary {
    width: 100%;
}

.ChangeImagePage-button-section {
    display: flex;
    flex-flow: column nowrap;
    gap: 2.5rem;
    margin-bottom: 3.5rem;
    align-items: center;
}

.ChangeImagePage-ChooseImageButton {
    width: 100%;
}

.ChangeImagePage-footer {
    display: flex;
    justify-content: space-between;
}

.ChangeImagePage-modal-image {
    display: flex;
    justify-content: center;
}

.ChangeImagePage-modal-button-container {
    margin-top: 8rem;
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
}

@media (max-width: 510px) {
    .ChangeImagePage-footer {
        justify-content: space-around;
        gap: 3rem;
    }
}


@media (max-width: 550px) {
    .ChangeImagePage-image {
        height: 12rem;
    }
    .ChangeImagePage-image-loading {
        animation: ChangeImagePage-loading 2.4s infinite
    }
    .ChangeImagePage-image-loading-background {
        height: 12rem;
        width: 12rem;
    }
}

@media (max-width: 360px) {
    .ChangeImagePage-image {
        height:10rem;
    }
    .ChangeImagePage-image-loading {
        background-position: -60px 0, 0 0px;
        animation: ChangeImagePage-loading 2.8s infinite
    }
    .ChangeImagePage-image-loading-background {
        height: 10rem;
        width: 10rem;
    }
}