.ContactAndHelpModal-section {
    line-height: 150%;
}

.ContactAndHelpModal-section h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 1.2rem;
  margin-bottom: 0;
}

.ContactAndHelpModal-data {
    text-decoration-line: underline;
    color: var(--link-color);
}

.ContactAndHelpModal-description {
  margin: 0;
}