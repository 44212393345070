.TopBar {
    background-color: var(--primary-color);
    width: 100%;
    height: 8rem;
    padding-left: 4.5rem;
    padding-right: 4.5rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    box-sizing: border-box;
    color: var(--primary-text-color);

    /* H1 Myriad Pro */
    /* font-family: Myriad Pro; */
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
}

.TopBar-logo {
    display: inline-block;
    margin-right: 1.2rem;
}

.Topbar-logo-redirect:focus {
    outline: var(--primary-text-color) solid 0.3rem;
    outline-offset: 0.2rem; 
}

.TopBar-logo.sq {
    width: 5.7rem;
    height: 5.7rem;
    display: none;
}

.TopBar-logo.wide {
    height: 5.7rem;
}

.TopBar-text {
    display: inline;
    position: absolute;
    top: 2rem;
}

.TopBar-name {
    right: 14rem;
}

.TopBar-language {
    right: 2rem;
}

.TopBar-language.full {
    display: inline-block;
}

.TopBar-expandicon {
    right: 4.6rem;
}

.TopBar-expandicon:hover {
    font-weight: bold;
    cursor:pointer;
}

.Topbar-logo-redirect {
    display: inline-block;
}

#name-dropdown {
    right: 25rem;
    display: inline;
    position: absolute;
    top: 4.2rem;
}

.TopBar-logout-button {
    right: 22rem;
    display: inline;
    position: absolute;
    top: 3rem;
    color: var(--primary-text-color);
    border: none;
    cursor: pointer;
    text-decoration: none;
    font: inherit;
    padding: 0;
}

.TopBar-logout-button:hover {
    text-decoration: underline;
}

@media (max-width: 1000px) {
    #name-dropdown {
        right: 20rem;
    }

}

@media (max-width: 720px) {
    .TopBar {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
    .TopBar-orgname {
        visibility: hidden;
    }
    .TopBar-logo.sq {
        display: inline-block;
    }

    .TopBar-logo.wide {
        display: none;
    }
}

@media (max-width: 550px) {
    .TopBar-language {
        right: 1rem;
    }
    #name-dropdown {
      right: 19rem;
    }
}

@media (max-width: 440px) {
    #name-dropdown {
        right: 16rem;
    }
    .TopBar-logout-button {
        right: 11rem;
    }
}
