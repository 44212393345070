.Button {
  display: inline-block;
  text-decoration: none;
  border: 0;
  background: none;
  box-shadow: none;
  border-radius: 0.4rem;
  padding: 1.1rem;
  background-color: var(--link-color);
  color: white;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  width: 18.8rem;
  line-height: 2.2rem;
}

.Button.fullWidth {
  width: 100%;
  height: auto;
  border: 0;
  padding: 0;
  background-color: var(--link-color);
}

.Button:hover {
  background-color: var(--link-dark-color);
}

.Button:active {
  background-color: var(--link-dark-color);
  color: var(--light-blue-color);
  text-decoration: underline;
}

.Button.disabled, .Button:disabled {
    background-color: var(--secondary-color-3);
    color: var(--black-text-color);
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.Button.big {
  width: 30rem;
  height: 15rem;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  margin: 0.8rem;
  font-size: 2.4rem;
  border-radius: 0.4rem;
  line-height: 15rem;
  flex-basis: 25rem;
  white-space: nowrap;
}

.Button.big:hover {
  background-color: var(--primary-active-color);
}

.Button.big:active {
  background-color: var(--primary-active-color);
}

.Button:focus {
  outline: var(--focus-color) solid 0.3rem;
  outline-offset: 0.2rem;
}

@media (max-width: 440px) {
  .Button {
    right: 1.6rem;
  }
}

@media (max-height: 760px) {
  .Button {
    bottom: 3.2rem;
  }
}
