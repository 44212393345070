
@keyframes ProfilePage-loading {
    to {
        background-position: 125px 0, 0 100px;
    }
}

.ProfilePage-image-container {
    height: 12rem;
    display: flex;
    justify-content: center;
}

.ProfilePage-image {
    clip-path: circle();
}

.ProfilePage-image-loading {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: 
        linear-gradient(0.25turn, transparent, #E9E9E9, transparent);
    background-repeat: no-repeat;
    background-size: 65% 100%, 100% 100%;
    background-position: -75px 0, 0 0px;
    animation: ProfilePage-loading 2s infinite
}

.ProfilePage-image-loading-background {
    width: 12rem;
    background-color: var(--secondary-color-3);
    border-radius: 50%;
}

.ProfilePage-image-error-text {
    text-align: center;
    color: red;
}

.ProfilePage-group-fields {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    justify-content: space-around;
    margin-top: 2.5rem;
}


.ProfilePage-disabled-input-field label input {
    display: flex;
    flex-flow: column nowrap;
    width: 16rem;
}

.ProfilePage-disabled-input-field label {
    color: #0075AF;
}

.ProfilePage-disabled-input-field input {
    height: 3rem;
    background: var(--secondary-color-3);
    border: 1px solid var(--disabled-color);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1.4rem;
    padding-left: 1rem;
    margin-bottom: 2.5rem;
}

.ProfilePage-button-container {
    display: flex;
    flex-flow: column nowrap;
    gap: 2.5rem;
    margin-top: 2.5rem;
}

@media (max-width: 480px)  {
    .ProfilePage-group-fields {
        justify-content: space-between;
    }

    .ProfilePage-disabled-input-field label input {
        width: 14rem;
    }
}

@media (max-width: 360px) {
    .ProfilePage-group-fields {
        justify-content: space-evenly;
    }

    .ProfilePage-disabled-input-field label input {
        max-width: 10rem;
    }
}
