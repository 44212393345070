/* The modal background element. */
.Modal-background {
    /* Darken the rest of the page. */
    background: rgba(0, 0, 0, 0.6);

    /* Cover the full page. */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    /* Center the actual modal. */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* The actual modal element. */
.Modal-main {
    /* Show the modal as a white box with a slight shadow. */
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

    /* Add som space around the edges. The top edge is handled by the title. */
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 4rem;

    /* Include the padding in the size calculations. */
    box-sizing: border-box;

    /* We want the modal to be at most 56 rem wide and as tall as required. */
    width: 56rem;
    /* But we do not want it to grow larger than the screen (minus a 1.6 rem
     * margin on all sides).
     */
    max-width: calc(100% - 2 * 1.6rem);
    max-height: calc(100% - 2 * 1.6rem);

    /* Ensure that child elements using `position: absolute` are positioned
     * relative to this element.
     */
    position: relative;

    /* Ensure that the modal can scroll if the content cannot fit. */
    overflow: auto;
}

.Modal-close-btn {
    float: right;
    position: absolute;
    top: 2.9rem;
    right: 2.9rem;
    width: 1.4rem;
    height: 1.4rem;
    border: 0;
    background-repeat: no-repeat;
    background-image: url("./clear_24px.svg");
    background-color: transparent;
    outline: none;
    cursor: pointer;
}
.Modal-close-btn:focus {
    outline: var(--focus-color) solid 0.3rem;
    outline-offset: 0.2rem;
}

.Modal-title {
    padding-top: 4.8rem;
    font-size: 2.4rem;
    font-weight: normal;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 2.4rem;
}


@media (max-width: 550px) {
    .Modal-main {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }
    .Modal-title {
        padding-top: 3.2rem;
        font-size: 2rem;
        font-weight: normal;
        line-height: 150%;
        margin-top: 0;
        margin-bottom: 1.6rem;
    }
}
