.LoginLinkVerifyCodePage p {
    font-size: 1.4rem;
}

.LoginLinkVerifyCodePage p.LoginLinkVerifyCodePage-number {
    margin-top: 1.9rem;
    font-size: 1.6rem;
    font-weight: 600;
}

.LoginLinkVerifyCodePage-code-status {
    margin-top: 0.1rem;
    padding-left: 0.2rem;
    min-height: 1.7rem;
    font-size: 1.2rem;
}
.LoginLinkVerifyCodePage-code-status-incorrect {
    color: #CF3C3A;
}

.LoginLinkVerifyCodePage-code-status-correct {
    color: white;
}

.LoginLinkVerifyCodePage-ButtonContainer {
    display: flex;
    flex-flow: column nowrap;
    gap: 3.9rem;
}

.LoginLinkVerifyCodePage-ButtonContainer > .ButtonSecondary {
    padding: 1rem 0;
    box-sizing: content-box;
}

.LoginLinkVerifyCodePage-Checkmark {
    position: absolute;
    left: 0.5em;
    margin-top: 1.5em;
}

.LoginLinkVerifyCodePage-Checkmark img {
    width: 5rem;
    height: 5rem;
}

@media (max-width: 319px) {
    .LoginLinkVerifyCodePage-Checkmark img {
        visibility: hidden;
    }
}


@media (max-width: 380px) {
    .LoginLinkVerifyCodePage-Checkmark {
        left: 0;
    }
}

@media (max-width: 440px) {
    .LoginLinkVerifyCodePage-Checkmark img {
        width: 3rem;
        height: 3rem;
    }
}