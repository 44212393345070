.OrderAccessCardPage-dropdown-container {
  margin-top: 3rem;
}

.OrderAccessCardPage-dropdown-select{
  display: block;
  margin: 0 auto;
  margin-top: 2rem;
  background: var(--secondary-color-2);
  border: 1px solid var(--disabled-color);
  border-radius: 4px;
  width:60%;
  height: 3.8rem;
  padding-left: 0.5rem;
  font-size: 1.6rem;
}

.OrderAccessCardPage-SuccessOrderingCard {
  display: grid;
  grid-template-columns: 6rem auto;
  grid-template-rows: auto auto;
  justify-content:space-evenly;
  align-items: center;
}

.OrderAccessCardPage-ErrorMessage {
  margin-top: 3rem;
  margin-bottom: 4rem;
}

.OrderAccessCardPage-footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

@media (max-width: 510px) {
  .OrderAccessCardPage-footer {
      justify-content: space-around;
      gap: 3rem;
  }
}