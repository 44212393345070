.ChangePinPage-footer {
    display: flex;
    gap: 3rem;
}

.ChangePinPage-status-success {
    display: grid;
    grid-template-columns: 6rem auto;
    grid-template-rows: auto auto;
    justify-content: space-evenly;
    align-items: center;
}
