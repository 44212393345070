.PasswordInput {
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin-bottom: 1.5rem;
}

.PasswordInput-input {
  position: relative;
}

.PasswordInput input {
    width: 100%;
    height: 4.5rem;
    background: #F5F5F5;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1.6rem;
    padding: 1.4rem;
    padding-right: 5rem;
    margin-top: 0.8rem;
    margin-right: 0.8rem;
}

.PasswordInput input:focus {
    outline: none;
    box-shadow: 0 0 0 0.3rem var(--focus-color);
}

.PasswordInput input.error {
    background: rgba(207, 60, 58, 0.08);
    border: 1px solid #CF3C3A;
    box-shadow: none;
}

.PasswordInput input.error:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem #cf3c3a;
}

.PasswordInput input.approved {
    background: rgba(94,206,84,0.08);
    border: 1px solid #5ECE54;
    box-shadow: 0 0 0 0.2rem #5ECE54;
}

.PasswordInput-eye {
    width: 2.4rem;
    height: 2.4rem;
    background-image: url('./eye.svg');
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
}

.PasswordInput-eye.visible {
    background-image: url('./eye-visible.svg');
}

.PasswordInput-eye:focus {
    outline: none;
    box-shadow: 0 0 0 0.3rem var(--focus-color);
}

.PasswordInput-checkmark {
    margin-left: -3.8rem;
    margin-right: 1rem;
    display: inline-block;
    position: absolute;
    bottom: 1rem;
}

.PasswordInput-bottomtext {
    margin-top: 0.1rem;
    padding-left: 0.2rem;
    min-height: 1.7rem;
}

.PasswordInput-message {
    color: #CF3C3A;
    font-size: 1.2rem;
}

.PasswordInput-message.ok {
    color: var(--bright-green-color);
    text-align: right;
    padding-right: 0.2rem;
}

@media (max-width: 440px) {
  .PasswordInput-checkmark {
    margin-left: -2.9rem;
  }
}

@media (max-width: 413px) {
    .PasswordInput-checkmark {
        visibility: hidden;
    }
    .PasswordInput {
        margin-left: unset;
    }
}

@media (max-height: 760px) {
    .PasswordInput {
        margin-bottom: 0.3rem;
    }
}
