.loadingSpinner {
  width: 2rem;
  height: 2rem;
  display: inline-block;
  border-radius: 50%;
  animation: spinner 1s ease-in-out infinite;
  border: 0.2rem solid transparent;
  border-top-color: var(--black-text-color);
}

@keyframes spinner {
  to { 
    transform: rotate(360deg); 
  }
}
