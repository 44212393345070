:root {
  /* Set root font size to 10px default equivalent to ease rem maths (default 16px) */
  font-size: 62.5%;
  --bg-color: #F7F8FA;
  --link-color: #0075AF;
  --link-dark-color: #00527B;
  --primary-color: #383838;
  --primary-active-color: #5C5C5C;
  --primary-text-color: #FFFFFF;
  --light-blue-color: #AEDEF5;
  --bright-green-color: #5ECE54;
  --secondary-color-2: #F5F5F5;
  --secondary-color-3: #D5D5D5;
  --secondary-color-4: #606060;
  --black-text-color: #333333;
  --white-text-color: #FFFFFF;
  --disabled-color: #B7B7B7;
  --focus-color:  rgba(251, 149, 75, 0.8);
  --secondary-focus-color: #333333;

  /* Content Container vars */
  --cc-width: 50.4rem;
  --cc-frame-height: 53.6rem;
  --cc-frame-height-small: 49rem;
  --cc-frame-height-xs: 43.5rem;
  --cc-margins-small: 0.8rem;
  --cc-title-size: 2.4rem;
  --cc-title-size-xs: 2rem;
  --cc-title-margin-bottom: 3.2rem;
  --cc-title-margin-bottom-small: 1.6rem;
  --cc-title-margin-bottom-xs: 1.6rem;
  --cc-padding-top: 6.4rem;
  --cc-padding-top-small: 4rem;
  --cc-padding-top-xs: 2.4rem;
  --cc-padding: 5.6rem;
  --cc-padding-small: 3.2rem;
  --cc-padding-xs: 1.6rem;
}

body {
  /* Reset default font size to 16px equivalent */
  font-size: 1.6rem;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--black-text-color);
}

a {
  color: var(--link-color);
}

a:focus {
  outline: var(--focus-color) solid 0.3rem;
  outline-offset: 0.2rem;
}

img {
  /* Ensure that images never cause scrollbars */
  max-width: 100%;
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.org-oslomet {
  --primary-color:#FFDC00;
  --primary-active-color: #EBC53C;
  --primary-text-color: #000000;
}

.org-uib {
  --primary-color:#CF3C3A;
  --primary-active-color: #AC2C2A;
}

.org-himolde {
  --primary-color: #355D98;
  --primary-active-color: #213B60;
}

.org-uit {
  --primary-color: #003349;
  --primary-active-color: #007396;
}

.org-nord {
  --primary-color: #165C7D;
  --primary-active-color: #5EC3C6;
}

.org-met {
  --primary-color: #018fa7;
  --primary-active-color: #0b455e;
}

.org-usn {
  --primary-color:#2f0b63;
  --primary-active-color: #9595f9;
}

.org-aho {
  --primary-color:#e54800;
  --primary-active-color: #b73900;
}

.org-nmbu {
  --primary-color:#008751;
  --primary-active-color: #025C4F;
}

.org-hvo {
  --primary-color:#00594E;
  --primary-active-color: #33A699;
}

.org-uis {
  --primary-color:#2e44be;
  --primary-active-color: #808fe1;
}