.BottomBar {
    width: 100%;
    height: 5.4rem;
    padding-top: 2rem;
    padding-bottom: 4.5rem;
    box-sizing: border-box;
    position: relative;
    bottom: 0;
    left: 0;

    /* H1 Myriad Pro */
    /* font-family: Myriad Pro; */
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    text-align: center;
}

.BottomBar-link {
    color: #333333;
    padding: 1.5rem;
}

.BottomBar-link:focus {
    outline: var(--focus-color) solid 0.3rem;
    outline-offset: 0.2rem;
}

.BottomBar-accessibility {
    font-size: 1.3rem;
}

@media (max-height: 760px) {
    .BottomBar {
        padding-bottom: 1rem;
    }
}

@media (max-height: 680px) {
    .BottomBar-link {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.supportBtn {
    background: none;
    border: none;
    padding: 0;
    color: var(--link-color);
    text-decoration: underline;
    cursor: pointer;
}

.supportBtn:focus {
    outline: var(--focus-color) solid 0.3rem;
    outline-offset: 0.2rem;
}

@media (max-height: 760px) {
    .BottomBar {
        padding-bottom: 1rem;
    }
}

