.ContentContainer > .BaseContainer-frame {
    display: flex;
    flex-direction: column;
}

.ContentContainer > .BaseContainer-frame > .ContentContainer-flex {
    flex-grow: 1;
}

.ContentContainer-title {
    font-size: var(--cc-title-size);
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: var(--cc-title-margin-bottom);
}

@media (max-height: 860px) {
    .ContentContainer-title {
        margin-bottom: var(--cc-title-margin-bottom-small);
    }
}

@media (max-height: 639px) {
    .ContentContainer-title {
        font-size: var(--cc-title-size-xs);
        margin-bottom: var(--cc-title-margin-bottom-xs);
    }
}
