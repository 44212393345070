/* Customize the label (the container) */
.Checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.Checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.Checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.4rem;
  width: 1.4rem;
  background-color: none;
  border-radius: 0.2rem;
  border: solid 0.2rem var(--black-text-color);
}

/* On focus, change border color */
.Checkbox-container input:focus ~ .Checkbox-checkmark {
  outline: var(--focus-color) solid 0.3rem;
  outline-offset: 0.2rem;
}

/* When disabled, change border color, pointer */
.Checkbox-container input:disabled ~ .Checkbox-checkmark {
  border: 0.2rem solid var(--disabled-color);
  border-radius: 0.2rem;
  background-color: var(--disabled-color);
  cursor: not-allowed;
}

/* When the checkbox is checked, add a blue background */
.Checkbox-container input:checked ~ .Checkbox-checkmark {
  background-color: var(--black-text-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.Checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.Checkbox-container input:checked ~ .Checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.Checkbox-container .Checkbox-checkmark:after {
  left: 0.4rem;
  top: 0;
  width: 0.3rem;
  height: 0.9rem;
  border: solid white;
  border-width: 0 0.25rem 0.25rem 0;
  transform: rotate(45deg);
}

