.PasswordForm-info {
    margin-bottom: 1.2rem;
}

.PasswordForm-helpBtn {
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 1.2rem;
  color: var(--link-color);
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

.PasswordForm-passwords {
    margin-top: 1rem;
}