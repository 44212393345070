.LoggedOutPage {
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin-top: 2.5rem;
}

.LoggedOutPage p {
    margin-bottom: 6rem;
}

.LoggedOutPage-bottom-container {
    position: relative;
    text-align: center;
    margin-top: 7rem;
}

@media (max-height: 760px) {
    .LoggedOutPage-bottom-container {
        margin-top: 3.2rem;
    }
}
