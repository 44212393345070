.PasswordChangeEmailSentPage-checkmark {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem;
}

.PasswordChangeEmailSentPage-buttonContainer {
    display: flex;
    flex-flow: row nowrap;
    gap: 1.6rem;
}
