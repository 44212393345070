.PasswordStrengthMeter {
    width: 100%;
    height: 0.4rem;
    background: #D5D5D5;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    border-radius: 5px;
}

.PasswordStrengthMeter div {
    display: block;
    height: 100%;
}

.PasswordStrengthMeter .weak {
    background-color: #CF3C3A;
}

.PasswordStrengthMeter .medium {
    background-color: #FFB800;
}

.PasswordStrengthMeter .good {
    background-color: #5ECE54;
}
