.ErrorPage > .BaseContainer-frame {
    border: 1px solid rgba(207,60,58,1);
}

.ErrorPage .ContentContainer-title {
    display: flex;
    align-items: center;
}

.ErrorPage-error-image {
    margin: 1rem;
    width: 20px;
    height: 20px;
}

.ErrorPage-details {
    margin-top: 1.6rem;
    margin-bottom: 2.4rem;
    padding: 0.8rem;
    font-family: 'Courier New', monospace;
    background-color: #EAE6E2;
}

.ErrorPage-button-container {
    margin-top: 3.5rem;
    display: flex;
    flex-direction: row-reverse;
}

@media (max-height: 639px) {
  .ErrorPage-title {
      font-size: var(--cc-title-size-xs);
      margin-bottom: var(--cc-title-margin-bottom-xs);
  }
}
