.ButtonSecondary, a.ButtonSecondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  gap: 1rem;
  text-decoration: none;
  border: 0;
  background: none;
  box-shadow: none;
  box-sizing: border-box;
  border-radius: 0.4rem;
  padding: 1.1rem;
  color: var(--link-color);
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  width: 18.8rem;
  height: 4.4rem;
  line-height: 2.2rem;
  cursor: pointer;
}

.ButtonSecondary.Contained {
  background-color: var(--link-color);
  color: var(--white-text-color)
}

.ButtonSecondary.Ghost {
  color: var(--link-color);
  background: none;
}

.ButtonSecondary.OutlinedGhost {
  border: 0.2rem solid var(--link-color);
  color: var(--link-color);
  background: none;
}

.ButtonSecondary:active {
  text-decoration: underline;
  background-color: var(--link-dark-color);
  color: var(--light-blue-color);
}

.ButtonSecondary:focus {
  outline: var(--focus-color) solid 0.3rem;
  outline-offset: 0.2rem;
}

.ButtonSecondary:hover {
  background-color: var(--light-blue-color);
  color: var(--link-dark-color)
}

.ButtonSecondary.Contained:hover {
  background-color: var(--link-dark-color);
  color: var(--white-text-color)
}

.ButtonSecondary a {
    text-decoration: none;
}

.ButtonSecondary.FullWidth {
    width: 100%;  
    padding: 0;
}

.ButtonSecondary.Disabled, .ButtonSecondary:disabled, .ButtonSecondary:disabled:hover {
  background-color: var(--secondary-color-3);
  color: var(--black-text-color);
  cursor: not-allowed;
}

@media (max-width: 440px) {
  .ButtonSecondary {
    right: 1.6rem;
  }
}

@media (max-height: 760px) {
  .ButtonSecondary {
    bottom: 3.2rem;
  }
}