.ContentProgress-bar {
    width: 100%;
    height: 0.8rem;
    background: #D5D5D5;
    vertical-align: middle;
    overflow: hidden;
}

.ContentProgress-bar-item {
    display: block;
    height: 100%;
    width: 12.50%;
    float: left;
    visibility: hidden;
    transition: background-color .2s, visisility .1s;
}
    
.ContentProgress-bar .active {
    visibility: visible;
    background-color: var(--link-color);
}
