.Checkmark {
    position: relative;
    text-align: center;
}

.Checkmark img {
    width: 15rem;
    height: 15rem;
    display: inline-block;
}
