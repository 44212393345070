.PasswordHelpModal-details {
  font-size: 1.4rem;
  line-height: 150%;
  padding: 0;
  margin: 0;
  padding-left: 1.6rem;
}

.PasswordHelpModal-details li {
  margin-bottom: 0.4rem;
}

@media (max-width: 550px) {
  .PasswordHelpModal-details li {
    margin-bottom: 1.4rem;
  }
}

@media (max-width: 320px) {
  .PasswordHelpModal-details {
    line-height: 1.8rem;
  }
}
