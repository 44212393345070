.BasePage-skip-link {
    background-color: var(--primary-color);
    color: var(--primary-text-color);
    left: 50%;
    padding: 0.4rem;
    position: absolute;
    transform: translateY(-125%);   
    transition: transform 0.3s;
}

.BasePage-skip-link:focus {
    transform: translateY(0%);
    outline: var(--primary-text-color) solid 0.3rem;
    outline-offset: 0.2rem;
}