.PasswordChangeWithoutIDPorten-form {
    margin-top: 5.7rem;
}

.PasswordChangeWithoutIDPorten-form input {
    width: 100%;
    height: 4.5rem;
    background: #F5F5F5;
    border: 1px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1.6rem;
    padding: 1.4rem;
    padding-right: 5rem;
    margin-top: 0.8rem;
    margin-right: 0.8rem;
}

.PasswordChangeWithoutIDPorten-info {
    margin-top: 2.4rem;
}

.PasswordChangeWithoutIDPorten-footer {
    display: flex;
    flex-direction: row-reverse;
}

.PasswordChangeWithoutIDPorten-error {
    margin-top: 0.1rem;
    padding-left: 0.2rem;
    min-height: 1.7rem;
    color: #CF3C3A;
    font-size: 1.2rem;
    font-weight: bold;
}
